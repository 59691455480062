/* eslint-disable react/prop-types */
import React from "react";
import { Link, graphql } from "gatsby";
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";
import P from "~components/typography/P";
import BlogCard from "~components/blog/BlogCard";
import NumberedSection from "~components/typography/NumberedSection";
import Grid from "~components/common/Grid";
import Divider from "~components/common/Divider";
import Breadcrumb from "~components/layouts/Breadcrumb";
import FormSearchPage from "~components/forms/FormSearchPage";

export default function Page({ data, location, pageContext }) {
	const breadcrumpPages = [{ name: "Blog", to: "/blog/" }];
	if (pageContext.currentPage > 1) {
		breadcrumpPages.push({ name: `Seite ${pageContext.currentPage}`, to: `/blog/seite/${pageContext.currentPage}/` });
	}
	return (
		<Layout
			location={location}
			title="Blog zu Webdesign und Marketing "
			desc="In unserem Blog fassen wir viele unterschiedliche Themen zusammen, die mit unserer Arbeit in Verbindung stehen."
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			ogType="article"
			canonical={`${data.site.siteMetadata.siteUrl}/blog/`}
		>
			<Breadcrumb pages={breadcrumpPages} />
			{pageContext.currentPage === 1 && (
				<Container noPadding className="py-6">
					<Grid cols={2}>
						<div>
							<Divider className="mb-4" />
							<NumberedSection text="Marketingtipps" number="01" className="mb-5" />
							<H1 display className="mb-5 font-display">
								Webdesign und Online Marketing Blog
							</H1>
							<P className="mb-5">
								Dich interessieren tiefere Einblicke in unsere Arbeit und unsere Technologie? In unserem Blog fassen wir
								viele unterschiedliche Themen zusammen, die mit unserer Arbeit in Verbindung stehen.
							</P>
							<FormSearchPage buttonText="Blog-Suche öffnen" slugFilter="/blog/" />
						</div>
					</Grid>
				</Container>
			)}

			<Container noPadding className="pb-16">
				{/* 			{pageContext.firstPage && (
					<div className="p-2 mb-8 transition-transform duration-300 bg-pattern hover:-translate-y-1">
					<div className="p-3 bg-white">
							<Grid cols={2}>
								<div>
									<Link to={`/blog/${data.firstEntry.nodes[0].titel_slug}/`}>
										<GatsbyImage
											image={getImage(data.firstEntry.nodes[0].remoteImage)}
											alt={data.firstEntry.nodes[0].titel}
											title={data.firstEntry.nodes[0].titel}
										/>
									</Link>
								</div>
								<div className="md:flex md:items-center">
									<div className="flex flex-col justify-between">
										<div className="flex-1">
											{data.firstEntry.nodes[0].thema.display && (
												<p className="text-sm font-medium text-gray-700">{data.firstEntry.nodes[0].thema.display}</p>
											)}

											<Link to={`/blog/${data.firstEntry.nodes[0].titel_slug}/`} className="block mt-2">
												<h2 className="text-xl font-semibold text-gray-900">{data.firstEntry.nodes[0].titel}</h2>
												<p className="mt-3 text-base text-gray-500">{data.firstEntry.nodes[0].einleitung}</p>
												<div className="flex flex-col mt-3 text-sm text-gray-500 md:space-x-1 md:flex-row">
													<time dateTime={data.firstEntry.nodes[0].datum}>{data.firstEntry.nodes[0].datum}</time>
													{data.firstEntry.nodes[0].text && (
														<>
															<span aria-hidden="true" className="hidden md:inline">
																·
															</span>
															<span>
																<EstimatedReadingTime text={data.firstEntry.nodes[0].text} />
															</span>
														</>
													)}
												</div>
											</Link>

											<Button
												text="Weiterlesen"
												to={`/blog/${data.firstEntry.nodes[0].titel_slug}/`}
												className="mt-3"
											/>
										</div>
									</div>
								</div>
							</Grid>
						</div>
					</div>
				)} */}

				<Grid cols={2} itemsStart>
					{data.allBlog.nodes.map((entry, i) => {
						if (i === 3) {
							return (
								<React.Fragment key={`${entry.titel}`}>
									<BlogCard
										link={`/blog/${entry.titel_slug}/`}
										topic={entry.thema.display}
										title={entry.titel}
										text={entry.einleitung}
										image={entry.remoteImage}
										button="Weiterlesen"
										date={entry.datum}
										mainText={entry.text}
									/>
								</React.Fragment>
							);
						}
						return (
							<BlogCard
								link={`/blog/${entry.titel_slug}/`}
								topic={entry.thema.display}
								title={entry.titel}
								text={entry.einleitung}
								image={entry.remoteImage}
								button="Weiterlesen"
								date={entry.datum}
								mainText={entry.text}
								key={entry.titel}
							/>
						);
					})}
				</Grid>

				<Pagination pageContext={pageContext} data={data} />
			</Container>
		</Layout>
	);
}

function Pagination({ pageContext, data }) {
	return (
		<nav className="my-8 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
			<div className="-mt-px flex w-0 flex-1">
				{pageContext.currentPage > 1 && (
					<Link
						to={`/blog/seite/${pageContext.currentPage - 1}/`}
						className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
					>
						{/* Heroicon name: solid/arrow-narrow-left */}
						<svg
							className="mr-3 h-5 w-5 text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
								clipRule="evenodd"
							/>
						</svg>
						Zurück
					</Link>
				)}
			</div>
			<div className="hidden md:-mt-px md:flex">
				{Array.from({
					length: Math.ceil(data.allBlog.totalCount / process.env.GATSBY_PAGE_SIZE),
				}).map((_, i) => {
					const pageNumber = i + 1;
					return (
						<Link
							to={`/blog/seite/${pageNumber}/`}
							className={`inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium ${
								pageNumber === pageContext.currentPage
									? "border-black text-black"
									: "text-gray-500 hover:border-gray-300 hover:text-gray-700"
							}`}
							key={`blogsite${pageNumber}`}
						>
							{pageNumber}
						</Link>
					);
				})}
			</div>
			<div className="-mt-px flex w-0 flex-1 justify-end">
				{pageContext.currentPage !== Math.ceil(data.allBlog.totalCount / process.env.GATSBY_PAGE_SIZE) && (
					<Link
						to={`/blog/seite/${pageContext.currentPage + 1}/`}
						className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
					>
						Nächste
						{/* Heroicon name: solid/arrow-narrow-right */}
						<svg
							className="ml-3 h-5 w-5 text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fillRule="evenodd"
								d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
					</Link>
				)}
			</div>
		</nav>
	);
}

export const query = graphql`
	query ($skip: Int, $pageSize: Int) {
		site {
			siteMetadata {
				siteUrl
			}
		}

		seoImage: file(relativePath: { eq: "pages/blog/blog_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1)
			}
		}

		allBlog(sort: { fields: datum, order: DESC }, skip: $skip, limit: $pageSize) {
			totalCount
			nodes {
				einleitung
				datum
				titel
				text
				titel_slug
				remoteImage {
					childImageSharp {
						gatsbyImageData
					}
				}
				thema {
					display
				}
			}
		}
	}
`;
