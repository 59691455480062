import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import Button from "../common/Button";
import EstimatedReadingTime from "./EstimatedReadingTime";

const BlogCard = ({ link, topic, tags, title, text, image, button, date, mainText }) => {
	const dateObject = new Date(date);
	return (
		<div className="bg-pattern p-2">
			<div className="bg-white p-3">
				<Link to={link}>
					<GatsbyImage image={getImage(image)} alt={title} title={title} className="mb-3" />
				</Link>
				<div className="flex flex-col justify-between">
					<div className="flex-1">
						{topic && <p className="text-sm font-medium text-gray-700">{topic}</p>}
						{tags &&
							tags.map((tag) => (
								<Link to={link} className="mr-2 bg-black px-2 py-1 text-sm text-white" key={tag}>
									{tag}
								</Link>
							))}

						<Link to={link}>
							<h2 className="mt-2 text-xl font-semibold text-gray-900 hover:underline">{title}</h2>
						</Link>
						<p className="mt-3 text-base text-gray-500">{text}</p>
						<div className="mt-3 flex flex-col text-sm text-gray-500 md:flex-row md:space-x-1">
							{date && (
								<time dateTime={date}>
									{dateObject.toLocaleDateString("de-DE", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})}
								</time>
							)}
							{mainText && (
								<>
									<span aria-hidden="true" className="hidden md:inline">
										·
									</span>
									<span>
										<EstimatedReadingTime text={mainText} />
									</span>
								</>
							)}
						</div>

						{button ? <Button text={button} to={link} className="mt-3" /> : ""}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogCard;

BlogCard.propTypes = {
	link: PropTypes.string.isRequired,
	image: PropTypes.objectOf(PropTypes.object).isRequired,
	topic: PropTypes.string,
	tags: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	button: PropTypes.string,
	date: PropTypes.string,
	mainText: PropTypes.string,
};

BlogCard.defaultProps = {
	topic: null,
	tags: null,
	button: null,
	date: null,
	mainText: null,
};
